import { useSelector } from 'react-redux';

// Hook
import { useEffect, useState } from 'react';
import useSnackbar from 'HOOKS/useSnackbar';

// Constant
import { HTML_ELEMENT_POSITION, SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { TAB_LOACTION } from 'CONSTANTS/apiConstants';
import {
  COPY_ELEMENT,
  LANGUAGE_PREFIX,
  TEAMS_ERROR,
  TEAMS_LANGUAGE_RESOURCE,
} from 'CONSTANTS/teamsConstants';

// Services
import { useTranslation } from 'react-i18next';
import logger from 'SERVICES/logger';

// Store
import { meetingSelector } from 'STORE/meetingSlice';

// hook to use copy function
const useCaptioner = (tabLocation: string) => {
  const showSnackbar = useSnackbar();
  const captionerSecret = useSelector(meetingSelector.captionerSecret);
  const { meetingId } = useSelector(meetingSelector.meetingDetails);
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  const [captionerLink, setCaptionerLink] = useState<string>('');

  const containerClass =
    tabLocation === TAB_LOACTION.inMeeting
      ? COPY_ELEMENT.ORGANIZER_IN_MEETING_CONTAINER
      : COPY_ELEMENT.ORGANIZER_EDITABLE_PRE_FORM_CONTAINER;

  // creating and storing captioner link
  useEffect(() => {
    if (meetingId && captionerSecret) {
      const captionerInterfaceLink = `${process.env.REACT_APP_CAPTIONER_URL}/${captionerSecret}/${meetingId}`;
      setCaptionerLink(captionerInterfaceLink);
    }
  }, [meetingId, captionerSecret]);

  // creating dummy element to be copied (not rendered)
  const createCopyLink = () => {
    // creating captioner link
    const copyLinkElement = `
        <p id="${COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID}">
            ${captionerLink}
        </p>
        `;
    document
      .getElementsByClassName(containerClass)[0]
      ?.insertAdjacentHTML(HTML_ELEMENT_POSITION.beforeEnd, copyLinkElement);
    return document.getElementById(COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID);
  };

  // copy function
  const copyLinkToClipboard = () => {
    const copyNode: any = createCopyLink();
    logger.debug('copyNode:', copyNode);
    try {
      // creating selection boject
      const selection = window.getSelection();
      logger.info('selection created: ', selection);
      // creating range object
      const range = document.createRange();
      logger.info('range created: ', range);
      // assigning the dummy element to the range
      range.selectNodeContents(copyNode);
      // removing all previously selected ranges
      selection?.removeAllRanges();
      // adding required range
      selection?.addRange(range);
      // executing copy to clipboard operation
      document.execCommand(COPY_ELEMENT.EXEC_COMMAND_COPY);
      // show success
      showSnackbar({
        message: t('CAPTIONER_LINK_COPY_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch {
      // show faliure
      showSnackbar({
        message: t('CAPTIONER_LINK_COPY_FAILURE'),
        type: SNACKBAR_TYPE.DANGER,
      });
    } finally {
      // cleanup
      copyNode.remove();
    }
  };

  // opens a popout browser window
  const openPopup = () => {
    try {
      const captionerWindow = window.open(captionerLink, '_blank');
      logger.debug('new window opened: ', captionerWindow);
    } catch (error) {
      logger.error(TEAMS_ERROR.OPEN_CAPTIONER, error);
    }
  };

  return { copyLinkToClipboard, openPopup };
};

export default useCaptioner;
