import { Flex, Text } from '@fluentui/react-northstar';
import { PreMeeting } from 'FEATURES/tab/preMeeting';
import InMeeting from 'FEATURES/tab/inMeeting/InMeeting';
import AkouoSupport from 'COMPONENTS/AkouoSupport';
import MicrosoftSignInButton from 'COMPONENTS/MicrosoftSignInButton';
import FullScreenLoader from 'COMPONENTS/FullScreenLoader';
import TabFooter from 'COMPONENTS/TabFooter';

// Constants
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { FRAME_CONTEXT } from 'CONSTANTS/enum';

// Hooks
import useTab from 'FEATURES/tab/hooks/useTabs';

// Services
import { useTranslation } from 'react-i18next';

import 'FEATURES/tab/Tab.css';

const Tab = () => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.TAB);
  const { frameContext, loading, isAuthorized, authenticate } = useTab();

  // if loading show loader
  if (loading) return <FullScreenLoader label={t('LOADING_TEAMS_ACCOUNT')} />;

  // prompt user to sign-in
  if (!isAuthorized) {
    return (
      <Flex fill hAlign="center" vAlign="center" column>
        <Text content={t('AUTH_ERROR_MSG')} align="center" />
        <AkouoSupport size="small" align="center" className="pt-1" />
        <MicrosoftSignInButton onClick={authenticate} />
      </Flex>
    );
  }

  // checking the tab location and rendering components accordingly
  const setFrame = () => {
    if (frameContext === FRAME_CONTEXT.sidePanel) return <InMeeting />;
    if (frameContext === FRAME_CONTEXT.PreMeeting) return <PreMeeting />;

    return <Text align="center" content={t('UNSUPPORTED_FRAME_MESSAGE')} />;
  };

  return (
    <Flex className="tab" fill column gap="gap.small">
      <Flex className="captionerInterface" fill>
        {setFrame()}
      </Flex>
      <TabFooter tabLocation={frameContext} />
    </Flex>
  );
};

export default Tab;
