import { ExclamationCircleIcon, Flex, FlexItem, Text } from '@fluentui/react-northstar';
import HyperLink from 'COMPONENTS/HyperLink';
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const UserDirection = () => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);

  const supportEmail = process.env.REACT_APP_AKOUO_SUPPORT_EMAIL;

  return (
    <Flex hAlign="start" gap="gap.small">
      <FlexItem>
        <Text>
          <ExclamationCircleIcon outline />
        </Text>
      </FlexItem>
      <Flex column>
        {!isMobile ? (
          <Text>
            {t('USER_SUBSCRIPTION_TEXT')}{' '}
            <HyperLink
              link={`${process.env.REACT_APP_AZURE_MARKETPLACE}`}
              label="Azure Marketplace"
            />{' '}
            {t('FREE_TRIAL_TEXT')}
          </Text>
        ) : null}
        <Text>
          {t('SUPPORT_TEXT')} <HyperLink link={`mailto:${supportEmail}`} label={supportEmail} />
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserDirection;
