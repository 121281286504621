import useTheme from 'HOOKS/useTheme';

const HyperLink = ({ link, label }: { link: string; label?: string }) => {
  const { theme } = useTheme();
  return (
    <a
      href={link}
      className="no-underline pl-1"
      style={{ color: theme.siteVariables?.colorScheme.brand.foreground }}
      target="_blank"
      rel="noreferrer"
    >
      {label ? label : link}
    </a>
  );
};

export default HyperLink;
