import { app } from '@microsoft/teams-js';

// Hooks
import { useEffect, useState } from 'react';

// Service
import logger from 'SERVICES/logger';

// Utils
import { getTeamsContext } from 'UTILS/utility';

const useTeams = () => {
  const [context, setContext] = useState<app.Context>();

  const initializeTeams = async () => {
    const teamsContext: any = await getTeamsContext();
    logger.debug('Context:', teamsContext);
    setContext(teamsContext);
  };

  // Initializing Teams app and getting meeting Context
  useEffect(() => {
    initializeTeams();
  }, []);
  return { context };
};

export default useTeams;
