// Hooks
import { useEffect, useState } from 'react';
import useTeams from 'HOOKS/useTeams';
import { useAuthentication } from 'FEATURES/authentication/hooks/useAuthentication';

// provides frameContext, loading, authentication state and auth function
const useTab = () => {
  const { context }: any = useTeams();
  const [frameContext, setframeContext] = useState('');
  const { isAuthorized, loading, authenticate } = useAuthentication();

  const tabAuthenticate = async () => {
    if (context?.page.frameContext) {
      // SSO authentication
      await authenticate();
      // checking tab location 'content' or 'sidepanel'
      setframeContext(context.page.frameContext);
    }
  };

  // check sso when context is changed
  useEffect(() => {
    if (context) {
      (async () => await tabAuthenticate())();
    }
  }, [context]);

  return { frameContext, loading, authenticate, isAuthorized };
};

export default useTab;
