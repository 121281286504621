import { Flex, Text } from '@fluentui/react-northstar';
import FullScreenLoader from 'COMPONENTS/FullScreenLoader';
import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';

// Constant
import {
  LANGUAGE_CAPTION_CODE,
  LANGUAGE_PREFIX,
  SCROLL_BEHAVIOUR,
  TEAMS_LANGUAGE_RESOURCE,
} from 'CONSTANTS/teamsConstants';
import { CAPTIONER_STATUS, THEME_COLOR, THEME_MODE, CALL_STATUS } from 'CONSTANTS/enum';

// Hooks
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'HOOKS/useActions';
import useTheme from 'HOOKS/useTheme';

// Service
import { useTranslation } from 'react-i18next';

// Store
import { meetingSelector } from 'STORE/meetingSlice';
import { captionsActions, captionsSelector } from 'STORE/captionsSlice';

import 'FEATURES/tab/inMeeting/liveCaption/LiveCaption.css';

export const LiveCaption = ({ loading }: { loading: boolean }) => {
  /**
   * const caption = [
   *  {
   *    id: messageId,
   *    name: captionData.speaker.displayName,
   *    text: captionData.text
   *  }
   * ]
   */

  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);
  const selectedLanguage = useSelector(meetingSelector.selectedSpokenLanguages);
  const currentCaption = useSelector(captionsSelector.currentCaption);
  const callStatus = useSelector(captionsSelector.callStatusSelector);
  const captionerStatus = useSelector(captionsSelector.captionerStatusSelector);
  const serverMessage = useSelector(captionsSelector.serverMessageSelector);
  const isCaptionerPresent = useSelector(captionsSelector.isCaptionerPresentSelector);
  const { clearCaptions } = useActions(captionsActions);
  const previousSpeker = useRef(null);
  const captionAreaRef: any = useRef(null);
  const captions: any = useRef([]);
  const { themeMode } = useTheme();

  const [renderComponent, setRenderComponent] = useState(Date.now());

  // when captionsData changes the caption area will be scrolled to bottom
  useEffect(() => {
    // set scroll to bottom
    if (captionAreaRef.current) {
      captionAreaRef.current.scrollTo({
        top: captionAreaRef.current.scrollHeight,
        behavior: SCROLL_BEHAVIOUR,
      });
    }
  }, [renderComponent, loading]);

  useEffect(() => {
    if (currentCaption && selectedLanguage?.code) {
      let captionLanguage = selectedLanguage.code.split('-')[0];
      captionLanguage =
        captionLanguage === LANGUAGE_CAPTION_CODE.ZH ? LANGUAGE_CAPTION_CODE.LZH : captionLanguage;
      const latestCaption = currentCaption.captions[captionLanguage];
      const isSpeakerChanged = previousSpeker.current !== currentCaption.speaker;
      captions.current.push(
        <Flex column gap="gap.smaller" key={Math.random().toString(16)}>
          <Flex vAlign="start" gap="gap.smaller">
            <Flex column gap="gap.smaller">
              {isSpeakerChanged && (
                <Text weight="semibold" color="brand" content={currentCaption.speaker} />
              )}
              <Text content={latestCaption} />
            </Flex>
          </Flex>
        </Flex>
      );
      previousSpeker.current = currentCaption.speaker;
      setRenderComponent(Date.now());
    }
  }, [currentCaption]);

  useEffect(() => {
    setRenderComponent(Date.now());
  }, [callStatus, serverMessage]);

  const render = () => {
    // render loader when captioner statue is connecting
    if (callStatus === CALL_STATUS.CONNECTING)
      return <FullScreenLoader label={t('CAPTIONER_JOINING')} />;

    // render warning when Captioner is not present in meeting
    if (!isCaptionerPresent) {
      // clearing captions array when captioner disconnectss
      if (currentCaption?.length !== 0 && captions.current?.length !== 0) {
        captions.current = [];
        clearCaptions();
        previousSpeker.current = null;
      }
      return <FullScreenWarning label={t('CAPTIONER_DISCONNECTED')} />;
    }

    // captions are off and captioner is connected in the meeting
    if (captionerStatus === CAPTIONER_STATUS.DISCONNECTED) {
      return <Text align="center" content={t('CAPTIONS_NOT_CONNECTED')} />;
    }

    // render loader when language is changed
    if (loading) return <FullScreenLoader label={t('SWITCHING_LANGUAGE')} />;

    // render message when captioner is connected but no captions is sent by captioner
    if (captions.current?.length === 0 && isCaptionerPresent)
      return <Text align="center" content={t('CAPTIONER_JOINED')} />;

    // Captions are present
    return captions.current;
  };

  return (
    <Flex
      fill
      column
      className="caption-area"
      style={
        themeMode === THEME_MODE.DARK
          ? { backgroundColor: THEME_COLOR.DARK }
          : { backgroundColor: THEME_COLOR.DEFAULT }
      }
      gap="gap.smaller"
      ref={captionAreaRef}
    >
      {render()}
    </Flex>
  );
};
