import { LOCAL_CACHE_PREFIX } from 'CONSTANTS/teamsConstants';
import { ILocalStorage } from 'UTILS/utilsInterface';

const meetingIdKey = `${LOCAL_CACHE_PREFIX}meetingId`;
const languageKey = `${LOCAL_CACHE_PREFIX}language`;
const userIdKey = `${LOCAL_CACHE_PREFIX}userId`;

// Object to access localStorage functions
const meetingLocalStorage = {
  // store data
  save({ meetingId, userId, language }: ILocalStorage) {
    // Store meetingId, languageCode and userId
    if (localStorage) {
      localStorage.setItem(meetingIdKey, String(meetingId));
      localStorage.setItem(languageKey, String(language));
      localStorage.setItem(userIdKey, String(userId));
    }
  },
  // returns languageCode
  get({ meetingId, userId }: ILocalStorage) {
    // if meetingId and userId is same as stored then return languageCode
    if (!localStorage.length) return null;
    const storedMeetingId = localStorage.getItem(meetingIdKey);
    const storedUserId = localStorage.getItem(userIdKey);
    if (storedUserId === String(userId) && storedMeetingId === String(meetingId)) {
      return localStorage.getItem(languageKey);
    }
    return null;
  },
  // localStorege cleaner
  remove() {
    // delete data from localStorage
    if (localStorage.length > 0) {
      localStorage.removeItem(meetingIdKey);
      localStorage.removeItem(languageKey);
      localStorage.removeItem(userIdKey);
    }
  },
};

export default meetingLocalStorage;
