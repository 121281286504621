import { Text } from '@fluentui/react-northstar';
import HyperLink from 'COMPONENTS/HyperLink';

// Constant
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Service
import { useTranslation } from 'SERVICES/i18n';

// Utils
import { IAkouoSupport } from 'UTILS/teamsInterface';

// renders Hyperlink to Akouo Support
const AkouoSupport = ({ size, align, className }: IAkouoSupport) => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);
  return (
    <Text size={size} align={align} className={className}>
      {t('NEED_ASSISTANCE')}
      <HyperLink link={`${process.env.REACT_APP_AKOUO_SUPPORT_LINK}`} />
    </Text>
  );
};

AkouoSupport.defaultProps = {
  size: undefined,
  align: undefined,
  className: undefined,
};

export default AkouoSupport;
