export const enum SNACKBAR_TYPE {
    INFO = 'info',
    DANGER = 'danger',
    SUCCESS = 'success',
    WARNING = 'warning',
};

export const enum SNACKBAR_DURATION {
    SHORT = 1000,
    MEDIUM = 2000,
    LONG = 4000,
};

export const enum THEME_MODE {
    DEFAULT = 'default',
    DARK = 'dark',
    CONTRAST = 'contrast',
};

export const enum USER_ROLE {
    ORGANIZER = 1,
    PARTICIPANT = 2,
    EXTERNAL = 3,
    ANONYMOUS = 4,
}

export const enum FRAME_CONTEXT {
    sidePanel = 'sidePanel',
    PreMeeting = 'content',
}

export const enum CALL_STATUS {
    CONNECTING = 'Connecting',
    INLOBBY = 'InLobby',
    CONNECTED = 'Connected',
    DISCONNECTED = 'Disconnected'
}

export const enum CAPTIONER_STATUS {
    CONNECTED = 'Connected',
    DISCONNECTED = 'Disconnected',
}

export const enum HTML_ELEMENT_POSITION {
    afterBegin = 'afterbegin',
    beforeEnd = 'beforeend',
    afterEnd = 'afterend',
};

export const enum THEME_COLOR {
    DEFAULT = '#E6E6E6',
    DARK = '#1A1A1A',
}

export const enum COPYRIGHT_TEXT_ALIGNMENT {
    CENTER = 'center',
    START = 'start',
    END = 'end',
}
