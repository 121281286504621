import { configureStore } from '@reduxjs/toolkit';
import appSlice from 'STORE/appSlice';
import meetingSlice from 'STORE/meetingSlice';
import captionsSlice from 'STORE/captionsSlice';

const store = configureStore({
    reducer: {
        app: appSlice,
        teamsMeeting: meetingSlice,
        captions: captionsSlice,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;