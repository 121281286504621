import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import arTranslations from 'RESOURCES/languages/ar.json';
import csTranslations from 'RESOURCES/languages/cs.json';
import daTranslations from 'RESOURCES/languages/da.json';
import deTranslations from 'RESOURCES/languages/de.json';
import elTranslations from 'RESOURCES/languages/el.json';
import enTranslations from 'RESOURCES/languages/en.json';
import esTranslations from 'RESOURCES/languages/es.json';
import fiTranslations from 'RESOURCES/languages/fi.json';
import frTranslations from 'RESOURCES/languages/fr.json';
import heTranslations from 'RESOURCES/languages/he.json';
import hiTranslations from 'RESOURCES/languages/hi.json';
import huTranslations from 'RESOURCES/languages/hu.json';
import itTranslations from 'RESOURCES/languages/it.json';
import jaTranslations from 'RESOURCES/languages/ja.json';
import koTranslations from 'RESOURCES/languages/ko.json';
import nlTranslations from 'RESOURCES/languages/nl.json';
import noTranslations from 'RESOURCES/languages/no.json';
import plTranslations from 'RESOURCES/languages/pl.json';
import ptTranslations from 'RESOURCES/languages/pt.json';
import roTranslations from 'RESOURCES/languages/ro.json';
import ruTranslations from 'RESOURCES/languages/ru.json';
import skTranslations from 'RESOURCES/languages/sk.json';
import svTranslations from 'RESOURCES/languages/sv.json';
import thTranslations from 'RESOURCES/languages/th.json';
import trTranslations from 'RESOURCES/languages/tr.json';
import ukTranslations from 'RESOURCES/languages/uk.json';
import zhTranslations from 'RESOURCES/languages/zh.json';

export { useTranslation } from 'react-i18next';
// the translations
// (manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// Additional resources are added in useAppInitializer.jsx
const resources = {
  ar: {
    translation: arTranslations,
  },
  cs: {
    translation: csTranslations,
  },
  da: {
    translation: daTranslations,
  },
  de: {
    translation: deTranslations,
  },
  el: {
    translation: elTranslations,
  },
  en: {
    translation: enTranslations,
  },
  es: {
    translation: esTranslations,
  },
  fi: {
    translation: fiTranslations,
  },
  fr: {
    translation: frTranslations,
  },
  he: {
    translation: heTranslations,
  },
  hi: {
    translation: hiTranslations,
  },
  hu: {
    translation: huTranslations,
  },
  it: {
    translation: itTranslations,
  },
  ja: {
    translation: jaTranslations,
  },
  ko: {
    translation: koTranslations,
  },
  nl: {
    translation: nlTranslations,
  },
  no: {
    translation: noTranslations,
  },
  pl: {
    translation: plTranslations,
  },
  pt: {
    translation: ptTranslations,
  },
  ro: {
    translation: roTranslations,
  },
  ru: {
    translation: ruTranslations,
  },
  sk: {
    translation: skTranslations,
  },
  sv: {
    translation: svTranslations,
  },
  th: {
    translation: thTranslations,
  },
  tr: {
    translation: trTranslations,
  },
  uk: {
    translation: ukTranslations,
  },
  zh: {
    translation: zhTranslations,
  },
};

// Resources for translations
export const addI18nResources = (nsName: string, languages: any) => {
  i18n.addResourceBundle('ar', nsName, languages.ar, true, true);
  i18n.addResourceBundle('cs', nsName, languages.cs, true, true);
  i18n.addResourceBundle('da', nsName, languages.da, true, true);
  i18n.addResourceBundle('de', nsName, languages.de, true, true);
  i18n.addResourceBundle('el', nsName, languages.el, true, true);
  i18n.addResourceBundle('en', nsName, languages.en, true, true);
  i18n.addResourceBundle('es', nsName, languages.es, true, true);
  i18n.addResourceBundle('fi', nsName, languages.fi, true, true);
  i18n.addResourceBundle('fr', nsName, languages.fr, true, true);
  i18n.addResourceBundle('he', nsName, languages.he, true, true);
  i18n.addResourceBundle('hi', nsName, languages.hi, true, true);
  i18n.addResourceBundle('hu', nsName, languages.hu, true, true);
  i18n.addResourceBundle('it', nsName, languages.it, true, true);
  i18n.addResourceBundle('ja', nsName, languages.ja, true, true);
  i18n.addResourceBundle('ko', nsName, languages.ko, true, true);
  i18n.addResourceBundle('nl', nsName, languages.nl, true, true);
  i18n.addResourceBundle('no', nsName, languages.no, true, true);
  i18n.addResourceBundle('pl', nsName, languages.pl, true, true);
  i18n.addResourceBundle('pt', nsName, languages.pt, true, true);
  i18n.addResourceBundle('ro', nsName, languages.ro, true, true);
  i18n.addResourceBundle('ru', nsName, languages.ru, true, true);
  i18n.addResourceBundle('sk', nsName, languages.sk, true, true);
  i18n.addResourceBundle('sv', nsName, languages.sv, true, true);
  i18n.addResourceBundle('th', nsName, languages.th, true, true);
  i18n.addResourceBundle('tr', nsName, languages.tr, true, true);
  i18n.addResourceBundle('uk', nsName, languages.uk, true, true);
  i18n.addResourceBundle('zh', nsName, languages.zh, true, true);
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
