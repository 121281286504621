import logger from 'SERVICES/logger';
import { API_URL, STATUS_CODE, AXIOS_CONSTANTS } from 'CONSTANTS/apiConstants';
import { createAxiosInstance, getAccessToken } from 'SERVICES/axiosInit';

const serverAPI = createAxiosInstance(API_URL);

// This function will be executed before every API's calls
serverAPI.interceptors.request.use(async (config: any) => {
  // Do something before request is sent
  logger.debug(`Server API call: ${config.method.toUpperCase()} ${config.url}`);
  logger.debug('Request Object  :', config);

  // adding auth token
  const accessToken = await getAccessToken?.();
  if (accessToken) {
    config.headers[AXIOS_CONSTANTS.REQUEST_HEADERS.AUTH_TOKEN_KEY] = `Bearer ${accessToken}`;
  }
  return config;
});

let setErrorTimestamp: () => void;

export const registerErrorCallback = (callback: () => void) => {
  setErrorTimestamp = callback;
};

const handleFailure = (error: any) => {
  let code = 0;
  let message = '';
  let data = {};
  let resCode = '';

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const resData = error.response.data;
    code = resData?.code || error.response.status;
    resCode = error.response.status;
    message = resData.message;
    data = resData.data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest
    message = error.message;
    code = STATUS_CODE.NETWORK_NOT_REACHABLE;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
    code = STATUS_CODE.ERROR_SETTING_UP_REQUEST;
  }

  return {
    message,
    code,
    data,
    resCode,
  };
};

// This function will be executed after every API's calls
serverAPI.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    logger.debug(`Successful API call ${response.config.url}`);
    logger.debug('Response Object: ', response);
    return response;
  },
  (error) => {
    if (error.response?.status === STATUS_CODE.INTERNAL_SERVER_ERROR) {
      setErrorTimestamp();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    logger.error(`Error Calling API ${error.config?.url}`, { error });
    return Promise.reject(handleFailure(error));
  }
);

export default serverAPI;
