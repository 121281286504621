// Constant
import { SNACKBAR_TYPE, USER_ROLE } from 'CONSTANTS/enum';
import { API_CONTEXT_TEAMS, ERROR, TAB_LOACTION } from 'CONSTANTS/apiConstants';
import { TEAMS_ERROR, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'HOOKS/useActions';
import useSnackbar from 'HOOKS/useSnackbar';
import useTeams from 'HOOKS/useTeams';

// Service
import { getMeetingDetails, putMeeting } from 'SERVICES/api';
import logger from 'SERVICES/logger';
import { useTranslation } from 'react-i18next';

// Store
import { meetingActions, meetingSelector, selectMeetingSpokenLanguages } from 'STORE/meetingSlice';
import { captionsActions } from 'STORE/captionsSlice';

// Utils
import meetingLocalStorage from 'UTILS/LocalStorage';
import { IMeetingOrganizer, IParicipantInfo } from 'UTILS/apiInterface';
import { ISpokenLanguage } from 'UTILS/teamsInterface';

const useInMeeting = () => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);
  const { context }: any = useTeams();

  // sets loader for the inMeeting page
  const [loading, setLoading] = useState(false);

  // sets loader to the dropdown when language is changed
  const [languageLoader, setLanguageLoader] = useState(false);

  const selectedSpokenLanguges = useSelector(meetingSelector.selectedSpokenLanguages);
  const spokenLanguages = useSelector(selectMeetingSpokenLanguages);
  const { setCurrentUserRole, setMeetingDetails, setSelectedSpokenLanguages } =
    useActions(meetingActions);
  const { setIsCaptionerPresent } = useActions(captionsActions);

  const setUserRole = async (organizer: IMeetingOrganizer, userObjectId: string) => {
    if (organizer?.id === userObjectId) {
      logger.info('Current user is an Organizer');
      // storing user role in store
      await setCurrentUserRole(USER_ROLE.ORGANIZER);
    } else {
      logger.info('Current user is not an Organizer');
      // storing user role in store
      await setCurrentUserRole(USER_ROLE.PARTICIPANT);
    }
  };

  const setSpokenLanguage = (language: ISpokenLanguage) => {
    setSelectedSpokenLanguages(language);
  };

  // getting Meeting Data on inMeeting Tab
  const fetchMeeting = async (meetingId: string) => {
    try {
      const meetingDetails = await getMeetingDetails(meetingId, TAB_LOACTION.inMeeting);
      // storing meeting Details in Store
      setMeetingDetails(meetingDetails);
      logger.debug('Meeting details: ', meetingDetails);
      await setUserRole(meetingDetails?.organizer, context?.user.id);
      setIsCaptionerPresent(meetingDetails.isCaptionerConnected);
      await setUserRole(meetingDetails?.organizer, context?.user.id);
    } catch (error: any) {
      logger.error(TEAMS_ERROR.FETCH_MEETING, error);
      if ([ERROR.MEETING_NOT_FOUND, ERROR.INVALID_MEETING_REQUEST].includes(error?.code)) {
        // No languages found for this meeting. Do nothing
        logger.debug('New Meeting: No information is present on server');
      } else {
        logger.error('Error while initializing in-meeting', error);
        showSnackbar({ message: error?.message, type: SNACKBAR_TYPE.DANGER });
      }
    }
    setLoading(false);
  };

  // function to update participant list
  const updateLanguage = async (language: ISpokenLanguage) => {
    // showing loader on language selection dropdown
    setLanguageLoader(true);
    // array to store updated data
    const updateData: IParicipantInfo[] = [];
    const { userPrincipalName, id } = context.user;
    const meetingId = context?.meeting.id;
    updateData.push({
      id: userPrincipalName,
      spokenLanguageCode: language.code,
      userObjectId: id,
      type: API_CONTEXT_TEAMS,
    });
    // if selected language data is present in localstorage then remove previously added data
    if (selectedSpokenLanguges?.code) {
      meetingLocalStorage.remove();
    }
    try {
      // storing selected language in local storage
      // null check for meetingId, userObjectId and language.code
      if (meetingId && id && language.code) {
        meetingLocalStorage.save({
          meetingId: meetingId,
          userId: id,
          language: language.code,
        });
      } else {
        throw new Error('problem in storing language Data: null detected');
      }
      // calling Update meeting API
      await putMeeting({
        meetingId,
        isPartialUpdate: true,
        participants: updateData,
      });

      // storing selected language in local storage
      meetingLocalStorage.save({
        meetingId: meetingId,
        userId: id,
        language: language.code,
      });
      // show success message
      showSnackbar({
        message: t('LANGUAGE_CHANGE_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch (error) {
      logger.error(TEAMS_ERROR.CHANGE_LANGUAGE, error);
      // show error message
      showSnackbar({
        message: t('LANGUAGE_CHANGE_ERROR'),
        type: SNACKBAR_TYPE.DANGER,
      });
      throw error;
    }
    // removing loader from language selection dropdown
    setLanguageLoader(false);
  };

  // function to connect to the previously selected language if any
  // when reloading the sidepanel or rejoining the meeting
  const checkPreviousLanguageConnection = async (meetingId: string, userId: string) => {
    const languageSelected = meetingLocalStorage.get({ meetingId, userId });
    if (languageSelected) {
      const selectedLanguage = spokenLanguages.find(
        (language: ISpokenLanguage) => language.code === languageSelected
      );
      setSpokenLanguage(selectedLanguage);
    }
  };

  // checking if previously connected to meeting when context and spokenLanguages are changed
  useEffect(() => {
    // connection to previous language if any
    if (context && spokenLanguages.length > 0) {
      (async () => checkPreviousLanguageConnection(context?.meeting.id, context?.user.id))();
    }
  }, [context, spokenLanguages]);

  // fetching meeting details when context is changed
  useEffect(() => {
    if (!context) return;
    setLoading(true);
    (async () => await fetchMeeting(context?.meeting.id))();
  }, [context]);

  return {
    pageLoader: loading,
    updateLanguage,
    setSpokenLanguage,
    languageLoader,
    userPrincipalName: context?.user.userPrincipalName,
    userObjectId: context?.user.id,
  };
};

export default useInMeeting;
