import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// From store
import { meetingSelector } from 'STORE/meetingSlice';
import useSnackbar from 'HOOKS/useSnackbar';
import { COPY_ELEMENT, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { HTML_ELEMENT_POSITION, SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { useTranslation } from 'react-i18next';
import logger from 'SERVICES/logger';

const useAua = () => {
  const { meetingId } = useSelector(meetingSelector.meetingDetails);
  const showSnackbar = useSnackbar();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const ct = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON).t;

  const [auaLink, setAuaLink] = useState<string>('');

  // creating dummy element to be copied (not rendered)
  const createCopyLink = () => {
    // creating aua link
    const copyLinkElement = `
        <div id="${COPY_ELEMENT.ORGANIZER_AUA_CONTAINER}">
        <qt text=black>
        <hr width="55%" align="left"/>
          <p>
            <font size=5>
              ${t('AUA_EMAIL_TITLE')}
            </font>
          </p>
          </br>
          <b>
            <p>
              ${t('AUA_EMAIL_DESCRIPTION')}
            </p>
          </b>
          <a href="${auaLink}">
            <u>
              ${t('AUA_EMAIL_LINK_DISPLAY')}
            </u>
          </a>
          </br>
          <hr width="55%" align="left"/>
        </qt>
        </div>
        `;
    document
      .getElementsByClassName(COPY_ELEMENT.ORGANIZER_EDITABLE_PRE_FORM_CONTAINER)[0]
      ?.insertAdjacentHTML(HTML_ELEMENT_POSITION.beforeEnd, copyLinkElement);
    return document.getElementById(COPY_ELEMENT.ORGANIZER_AUA_CONTAINER);
  };

  const copyAuaLink = () => {
    logger.debug('copyAuaLink called');
    const copyNode: any = createCopyLink();
    try {
      // creating selection boject
      const selection = window.getSelection();
      logger.info('selection created: ', selection);
      // creating range object
      const range = document.createRange();
      logger.info('range created: ', range);
      // assigning the dummy element to the range
      range.selectNodeContents(copyNode);
      // removing all previously selected ranges
      selection?.removeAllRanges();
      // adding required range
      selection?.addRange(range);
      // executing copy to clipboard operation
      document.execCommand(COPY_ELEMENT.EXEC_COMMAND_COPY);
      // show success
      showSnackbar({
        message: ct('CAPTIONER_LINK_COPY_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch (error) {
      logger.error('Error while copying AUA link', error);
      showSnackbar({
        message: ct('CAPTIONER_LINK_COPY_FAILURE'),
        type: SNACKBAR_TYPE.DANGER,
      });
    } finally {
      // cleanup
      copyNode.remove();
    }
  };

  // creating and storing aua link
  useEffect(() => {
    if (meetingId) {
      const auaInterfaceLink = `${process.env.REACT_APP_AUA_URL}/${meetingId}`;
      setAuaLink(auaInterfaceLink);
    }
  }, [meetingId]);

  return {
    copyAuaLink,
  };
};

export default useAua;
