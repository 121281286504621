import { Flex, Text } from '@fluentui/react-northstar';
import { PreMeetingForm, PreMeetingReview } from 'FEATURES/tab/preMeeting';
import AkouoSupport from 'COMPONENTS/AkouoSupport';
import FullScreenLoader from 'COMPONENTS/FullScreenLoader';
import MicrosoftSignInButton from 'COMPONENTS/MicrosoftSignInButton';
import UserDirection from 'COMPONENTS/userDirections/UserDirections';

// Constant
import { USER_ROLE } from 'CONSTANTS/enum';

// Hooks
import usePremeeting from 'FEATURES/tab/preMeeting/hooks/usePreMeeting';
import { useSelector } from 'react-redux';

// Slice
import { meetingSelector as selector } from 'STORE/meetingSlice';

const PreMeeting = () => {
  const {
    editMode,
    languageOptions,
    isAuthorized,
    loader,
    auaFeatureEnabled,
    setEditMode,
    fetchMeeting,
    onSubmit,
    t,
  } = usePremeeting();

  const currentUserRole = useSelector(selector.currentUserRole);

  // Shows fullscreen loader
  if (loader.isLoading) return <FullScreenLoader label={loader.message} />;

  // if not authorized user will be prompted with signin button
  if (!isAuthorized) {
    return (
      <Flex fill hAlign="center" vAlign="center" column>
        <Text content={t('AUTH_ERROR_MSG')} align="center" />
        <AkouoSupport size="small" align="center" className="pt-1" />
        <MicrosoftSignInButton onClick={() => fetchMeeting({ usePermissionScope: true })} />
      </Flex>
    );
  }

  return (
    <Flex column className="pre-meeting-container" gap="gap.large">
      <Flex className="pre-meeting-container__from">
        {editMode ? (
          <PreMeetingForm
            handleSubmit={onSubmit}
            languages={languageOptions}
            auaFeatureEnabled={auaFeatureEnabled}
            handleCancelButton={() => setEditMode(false)}
          />
        ) : (
          <PreMeetingReview
            auaFeatureEnabled={auaFeatureEnabled}
            handleEditClick={() => setEditMode(true)}
          />
        )}
      </Flex>
      {currentUserRole === USER_ROLE.ORGANIZER && <UserDirection />}
    </Flex>
  );
};

export default PreMeeting;
