import { Flex, Text, Button, FlexItem, Checkbox } from '@fluentui/react-northstar';
import AkouoSupport from 'COMPONENTS/AkouoSupport';

// Constants
import { USER_ROLE } from 'CONSTANTS/enum';
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { TAB_LOACTION } from 'CONSTANTS/apiConstants';

// Hooks
import { useSelector } from 'react-redux';
import useCaptioner from 'HOOKS/useCaptioner';
import useAua from 'HOOKS/useAua';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Slice
import {
  selectMeetingLanguage,
  selectUniversalAccessEnabled,
  meetingSelector as selector,
} from 'STORE/meetingSlice';

// Utils
import { IPreMeetingReview, ISetLanguage } from 'UTILS/teamsInterface';
import { TFunction } from 'i18next';

import 'FEATURES/tab/preMeeting/preMeetingReview/preMeetingReview.css';

// component to show different content to the praticipants
const ParticipantInformation = ({ noLanguages, t }: { noLanguages: boolean; t: TFunction }) => {
  return (
    <Flex column>
      <span>
        {noLanguages
          ? t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')
          : t('LANGUAGES_CONFIGURED_PARTICIPANT')}
      </span>
      <AkouoSupport />
    </Flex>
  );
};

// PreMeetingReview tab component
// handleclick is a setState function which toggels between editMode
const PreMeetingReview = ({ auaFeatureEnabled, handleEditClick }: IPreMeetingReview) => {
  const selectedLanguages = useSelector(selectMeetingLanguage);
  const universalAccessEnabled = useSelector(selectUniversalAccessEnabled);
  const currentUserRole = useSelector(selector.currentUserRole);
  const languages = useSelector(selector.meetingLanguages);
  const isUserOrganizer = currentUserRole === USER_ROLE.ORGANIZER;
  const noLanguage = selectedLanguages.length === 0;
  const { copyLinkToClipboard } = useCaptioner(TAB_LOACTION.preMeeting);
  const { copyAuaLink } = useAua();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);

  return (
    <Flex column gap="gap.large" className="pre-meeting-review-container">
      <Flex gap="gap.small" column>
        <Text size="large" weight="bold" content={t('MEETING_LANGUAGES_LABEL')} />
        <Text className="review-container-text">
          {!isUserOrganizer && <ParticipantInformation noLanguages={noLanguage} t={t} />}
        </Text>
        {noLanguage ? (
          <Flex>{t('NO_LANGUAGES_CONFIGURED_ORGANIZER')}</Flex>
        ) : (
          <Flex wrap>
            <Text className="language-name">
              {languages
                .map((item: ISetLanguage) => {
                  // null check
                  if (item.name) return item.name;
                })
                .join(', ')}
            </Text>
          </Flex>
        )}
      </Flex>

      {isUserOrganizer && (
        <Flex column gap="gap.large">
          <Flex column gap="gap.small" className="review-actions">
            {auaFeatureEnabled && (
              <>
                <Checkbox
                  className="dropdown-label"
                  label={t('AUA_TITLE')}
                  style={{ maxWidth: '20em', pointerEvents: 'none' }}
                  checked={universalAccessEnabled}
                />
                {universalAccessEnabled && (
                  <Flex className="my-2">
                    <Button
                      content={t('COPY_LINK_FOR_PARTICIPANTS')}
                      secondary
                      onClick={copyAuaLink}
                    />
                  </Flex>
                )}
              </>
            )}

            <FlexItem>
              {noLanguage ? (
                <Text className="review-container-text" content={t('ADD_REQUEST_LABEL')} />
              ) : (
                <Text className="review-container-text" content={t('EDIT_REQUEST_LABEL')} />
              )}
            </FlexItem>
            <FlexItem>
              <Button
                content={noLanguage ? t('ADD_LANG_BTN') : t('EDIT_LANG_BTN')}
                primary
                onClick={() => handleEditClick()}
                className="premeeting-button"
              />
            </FlexItem>
          </Flex>
          {!noLanguage && (
            <Flex gap="gap.smaller" column>
              <FlexItem>
                <Text className="review-container-text" content={t('COPY_LINK_DIRECTIONS')} />
              </FlexItem>
              <FlexItem>
                <Button
                  content={t('COPY_CAPTIONER_LINK')}
                  className="copy-link-button premeeting-button"
                  onClick={copyLinkToClipboard}
                />
              </FlexItem>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default PreMeetingReview;
