import { CallEndIcon, Flex, Text } from '@fluentui/react-northstar';
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { useTranslation } from 'react-i18next';

const CallEnded = () => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);

  return (
    <Flex fill vAlign="center" hAlign="center" column>
      <CallEndIcon size='larger' />
      <Text content={t('CALL_ENDED')}  size='large'/>
    </Flex>
  );
};

export default CallEnded;
