import { JSONTypes, WebPubSubClient, WebPubSubDataType } from '@azure/web-pubsub-client';
import logger from 'SERVICES/logger';

class PubSubClient {
  // PubSub Events
  static PUBSUB_EVENTS = Object.freeze({
    connected: 'connected',
    disconnected: 'disconnected',
    rejoinGroupFailed: 'rejoin-group-failed',
    stopped: 'stopped',
    serverMessage: 'server-message',
    groupMessage: 'group-message'
  });

  // Events for outerworld to subscribe
  static WS_EVENT = {
    WS_CONNECTED: 'ws-connected',
    WS_DISCONNECTED: 'ws-disconnected',
    CAPTIONS: 'captions',
    CAPTIONER_STATUS: 'captioner-status',
    CALL_STATUS: 'call-status',
    MST_SPEAKING_LANGUAGE_CHANGED: 'mst-speaking-language-changed',
    WS_MESSAGE: 'ws-message',
    WS_ERROR: 'ws-error',
  };

  // Pubsub socket client
  private pubsubClient: WebPubSubClient | undefined;

  // Listeners for subscribed events/ unsubscribe
  listeners = new Map();

  // Event listeners for pubsub client
  connectedListener: any;
  disconnectedListener: any;
  groupMessageListener: any;
  rejoinGroupFailedListener: any;
  serverMessageListener: any;
  stoppedListener: any;

  async init(socketUrl: string) {
    try {
      // Creating pubsub client
      this.pubsubClient = new WebPubSubClient(socketUrl);

      // Adding event listeners
      this.connectedListener = (eventData: any) => {
        logger.debug(`[PUBSUBCLIENT] Connected to server: ${eventData}`);
        this.notify(PubSubClient.WS_EVENT.WS_CONNECTED, eventData);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.connected, this.connectedListener);

      this.disconnectedListener = (eventData: any) => {
        logger.debug(`[PUBSUBCLIENT] Disconnected from server: ${eventData}`);
        this.notify(PubSubClient.WS_EVENT.WS_DISCONNECTED, eventData);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.disconnected, this.disconnectedListener);

      this.rejoinGroupFailedListener = (eventData: any) => {
        logger.debug(`[PUBSUBCLIENT] Rejoin group failed: ${eventData}`);
        this.notify(PubSubClient.WS_EVENT.WS_ERROR, eventData);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.rejoinGroupFailed, this.rejoinGroupFailedListener);

      this.stoppedListener = (eventData: any) => {
        logger.debug(`[PUBSUBCLIENT] Stopped: ${eventData}`);
        this.notify(PubSubClient.WS_EVENT.WS_ERROR, eventData);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.stopped, this.stoppedListener);

      this.serverMessageListener = (eventData: any) => {
        logger.debug('[PUBSUBCLIENT] Server message received:', eventData.message.data);
        this.notify(PubSubClient.WS_EVENT.WS_MESSAGE, eventData.message.data);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.serverMessage, this.serverMessageListener);

      this.groupMessageListener = (eventData: any) => {
        logger.debug(`[PUBSUBCLIENT] Group message received: ${JSON.stringify(eventData)}`);
        this.handleGroupMessage(eventData);
      }
      this.pubsubClient.on(PubSubClient.PUBSUB_EVENTS.groupMessage, this.groupMessageListener);

      // Starting pubsub client
      await this.pubsubClient.start();
    } catch (error) {
      logger.error('Error: unable to create Pubsub client');
      throw error;
    }
  }

  private handleGroupMessage(eventData: any) {
    const { data } = eventData.message;

    // This will be used for captioner to update the spoken language of team participant
    if (data.userSpokenLanguageChanged) {
      this.notify(PubSubClient.WS_EVENT.MST_SPEAKING_LANGUAGE_CHANGED, data.userSpokenLanguageChanged);
      return;
    }
    // This will be used for captioner to update the status of team participant
    if (data.userConnected) {
      this.notify(PubSubClient.WS_EVENT.WS_MESSAGE, data);
      return;
    }
    // This will be used for captioner to update the status of team participant
    if (data.userDisconnected) {
      this.notify(PubSubClient.WS_EVENT.WS_MESSAGE, data.userDisconnected);
      return;
    }
    // This will be used for teams participant to show busy indicator
    if (data.captionerStatus) {
      this.notify(PubSubClient.WS_EVENT.CAPTIONER_STATUS, data.captionerStatus);
      return;
    }
    // This will be used for teams participant to show busy indicator
    if (data.callStatus) {
      this.notify(PubSubClient.WS_EVENT.CALL_STATUS, data.callStatus);
      return;
    }
    // This will be used for teams participant to show captions
    if (data.captionsData) {
      this.notify(PubSubClient.WS_EVENT.CAPTIONS, data.captionsData);
      return;
    }
    // This will be used for teams participant to notify current speaker
    if (data.activeSpeakerChanged) {
      this.notify(PubSubClient.WS_EVENT.WS_MESSAGE, data.activeSpeakerChanged);
      return;
    }
    // This will be used when above conditions are false
    this.notify(PubSubClient.WS_EVENT.WS_MESSAGE, data);
  }

  async cleanup() {
    // Removing event listeners
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.connected, this.connectedListener);
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.disconnected, this.disconnectedListener);
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.groupMessage, this.groupMessageListener);
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.rejoinGroupFailed, this.rejoinGroupFailedListener);
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.serverMessage, this.serverMessageListener);
    this.pubsubClient?.off(PubSubClient.PUBSUB_EVENTS.stopped, this.stoppedListener);

    // Stopping pubsub client
    this.pubsubClient?.stop();

    // Clearing map listeners
    this.listeners.clear();
  }

  /**
   * Function used to subscribe to events
   * @param eventName name of event
   * @param callback callback function to be called
   * @returns random subscription id
   */
  subscribe(eventName: string, callback: any) {
    try {
      logger.debug(`[PUBSUBCLIENT] Subscribing to event: ${eventName}`);
      let listenersForEvent = this.listeners.get(eventName);

      if (!listenersForEvent) {
        listenersForEvent = [];
      }
      const randomId = (Math.random() + 1).toString(36).substring(2);
      listenersForEvent.push({ id: { eventName, eventId: randomId }, callback });
      this.listeners.set(eventName, listenersForEvent);
      logger.debug(`[PUBSUBCLIENT] Subscribed to event: ${eventName}`);
      return randomId;
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error subscribing to event: ${error}`);
      throw error;
    }
  }

  /**
   * Function used to unsubscribe from events
   * @param eventName name of event
   * @param id subcription id
   */
  unsubscribe(eventName: string, id: string) {
    try {
      logger.debug(`[PUBSUBCLIENT] Unsubscribing from event: ${eventName}`);
      const listenersForEvent = this.listeners.get(eventName);
      if (listenersForEvent) {
        const filteredListeners = listenersForEvent.filter(
          (listenerObj: any) => listenerObj.id !== id
        );
        this.listeners.set(eventName, filteredListeners);
      }
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error unsubscribing from event: ${error}`);
      throw error;
    }

    logger.debug(`[PUBSUBCLIENT] Unsubscribed from event: ${eventName}`);
  }

  private notify(eventName: string, data: any) {
    try {
      logger.debug(`[PUBSUBCLIENT] Notifying event: ${eventName}`);
      let eventData: any;
      switch (eventName) {
        case PubSubClient.WS_EVENT.WS_CONNECTED:
          logger.debug('[PUBSUBCLIENT] Connected: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.WS_DISCONNECTED:
          logger.debug('[PUBSUBCLIENT] Disconnected: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.WS_ERROR:
          logger.debug('[PUBSUBCLIENT] Ws-error: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.WS_MESSAGE:
          logger.debug('[PUBSUBCLIENT] Ws-messages: ', data);
          if (!data.userConnected)
            eventData = data;
          break;
        case PubSubClient.WS_EVENT.CAPTIONER_STATUS:
          logger.debug('[PUBSUBCLIENT] Captioner status: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.CALL_STATUS:
          logger.debug('[PUBSUBCLIENT] Call status: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.CAPTIONS:
          logger.debug('[PUBSUBCLIENT] Captions: ', data);
          eventData = data;
          break;
        case PubSubClient.WS_EVENT.MST_SPEAKING_LANGUAGE_CHANGED:
          logger.debug('[PUBSUBCLIENT] Captions: ', data);
          eventData = data;
          break;
        default:
          logger.debug(`[PUBSUBCLIENT] Unknown event: ${eventName}`);
      }
      const eventListeners = this.listeners.get(eventName);
      if (eventListeners) {
        eventListeners.forEach((listenerObj: any) => {
          listenerObj.callback(eventData);
        });
      }
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error notifying event: ${error}`);
      throw error;
    }
  }

  /**
   * This function will be used to send message to group
   * @param group Name of group to which we need to send message
   * @param message actual message
   * @param type type of message
   */
  async sendMessageToGroup(group: string, message: JSONTypes, type: WebPubSubDataType) {
    logger.debug(`[PUBSUBCLIENT] Sending message to group ${group}: ${message}`);
    try {
      await this.pubsubClient?.sendToGroup(group, message, type);
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error sending message to group ${group}: ${error}`);
      throw error;
    }
  }

  /**
   * This function will be used to send message to server
   * @param eventName which event we need to send to server
   * @param message actual message
   * @param type type of message
   */
  async sendMessageToServer(eventName: string, message: string, type: WebPubSubDataType) {
    logger.debug(`[PUBSUBCLIENT] Sending message to server: ${message}`);
    try {
      await this.pubsubClient?.sendEvent(eventName, message, type);
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error sending message to server: ${error}`);
      throw error;
    }
  }

  /**
   * This function will be used to join the group
   * @param group Name of group to which we need to Join
   */
  async joinGroup(group: string) {
    try {
      logger.debug(`[PUBSUBCLIENT] Joining group ${group}`);
      await this.pubsubClient?.joinGroup(group);
    } catch (error) {
      logger.error(`[PUBSUBCLIENT] Error joining group ${group}: ${error}`);
      throw error;
    }
  }
}

export default PubSubClient;
