import { createSlice } from '@reduxjs/toolkit';
import { THEME_MODE, SNACKBAR_DURATION, SNACKBAR_TYPE } from 'CONSTANTS/enum';
import {ISnackBar} from 'UTILS/teamsInterface'

interface IInitialState {
    themeMode: string,
    snackbar: ISnackBar,
    errorTimestamp: number,
}

const initialState: IInitialState = {
    themeMode: THEME_MODE.DEFAULT,
    snackbar: {
        show: false,
        message: 'Set message to show',
        duration: SNACKBAR_DURATION.MEDIUM,
        type: SNACKBAR_TYPE.INFO,
    },
    errorTimestamp: 0,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppThemeMode(state, action) {
            state.themeMode = action.payload;
        },
        showSnackbar: (state, action) => {
            Object.assign(state.snackbar, { ...initialState.snackbar, ...action.payload, show: true });
        },
        hideSnackbar: (state) => {
            state.snackbar = initialState.snackbar;
        },
        setErrorTimestamp(state) {
            state.errorTimestamp = Date.now();
        },
    },
});

export const appActions = { ...appSlice.actions };

export const appSelector = {
  errorTimestamp: (state: any) => state.app.errorTimestamp,
};
export default appSlice.reducer;
