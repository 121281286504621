import serverAPI from 'SERVICES/serverAPI';
import { API_CONTEXT_TEAMS, API_ENDPOINTS } from 'CONSTANTS/apiConstants';
import { IPutMeetingData } from 'UTILS/apiInterface';

export const getLanguages = async () => {
  const url = `${API_ENDPOINTS.LANGUAGE}`;
  const response = await serverAPI.get(url);
  return response.data;
};

/**
 * Fetch Meeting details from backend.
 * @param {String} meetingId: Teams Meeting Id
 * @param {?String} code: Authentication code received from auth code flow
 * @returns response data
 */
export const getMeetingDetails = async (meetingId: string, location: string, code?: any) => {
  const url = `${API_ENDPOINTS.MEETING}/${meetingId}/${location}/${API_CONTEXT_TEAMS}`;
  const response = await serverAPI.get(url, { params: { code } });
  return response.data;
};

/**
 * Create or update meeting
 * @param {IPutMeetingData} meetingData: Meeting data consist of
 *
 * @param {string} meetingId: Teams meeting Id
 * @param {boolean} isPartialUpdate: flag for update or create methods
 * @param {[ISetLanguage]} languages : optional array of language Object
 * @param {[IParicipantInfo]} participants : optional array of participant information
 * @returns
 */
export const putMeeting = async (meetingData: IPutMeetingData) => {
  const url = `${API_ENDPOINTS.MEETING}/${API_CONTEXT_TEAMS}`;
  return serverAPI.post(url, meetingData);
};

/**
 * This will help us to check if feature is enable or not for customer
 * @param tenantId
 * @returns
 */
export const getCustomerEnabledForAua = async (tenantId: string) => {
  const url = `${API_ENDPOINTS.CUSTOMER_ENABLE_FEATURE}/${tenantId}`;
  const response = await serverAPI.get(url);
  return response.data;
};
