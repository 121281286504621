// App Constants
import { IRoutes } from 'UTILS/teamsInterface';

export const ORIGIN = window.location.origin;
export const APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CONSENT_STATE_KEY = 'Graph-consent-state';
export const TEAMS_LANGUAGE_RESOURCE = 'teams';
export const MIN_LANGUAGES_SELECTED = 1;
export const LOCAL_CACHE_PREFIX = 'akouo-caption-';
export const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} Akouo Technologies Limited`;
export const SCROLL_BEHAVIOUR = 'smooth';

export const ROUTES: Readonly<IRoutes> = {
  TAB: '/tab',
  CONFIG: '/config',
  CONSENT_POPUP: '/auth-start',
  CLOSE_POPUP: '/auth-end',
};

export const QUERY_PARAMS =
  'client_id={APP_CLIENT_ID}&response_type=code&response_mode=fragment&redirect_uri={ORIGIN}{ROUTES.CLOSE_POPUP}&state={uniqueState}&scope={scope}';

export const LANGUAGE_PREFIX = {
  COMMON: { keyPrefix: 'COMMON' },
  TAB: { keyPrefix: 'TAB' },
  TAB_CONFIG: { keyPrefix: 'TAB_CONFIG' },
  PREMEETING: { keyPrefix: 'PREMEETING' },
  INMEETING: { keyPrefix: 'INMEETING' },
} as const;

export const TEAMS_ERROR = {
  SIGNIN: 'Error: unable to sign in',
  FETCH_MEETING: 'Error: unable to fetch meeting details',
  CHANGE_LANGUAGE: 'Error: unable to change Language',
  FETCH_LANGUAGE: 'Error: unable to fetch supported languages',
  SUBMIT_REQUEST: 'Error: unable to submit request',
  OPEN_CAPTIONER: 'Error: unable to open Captioner',
  FETCH_AUA_ALLOWED: 'Error: unable to fetch feature AUA status',
} as const;

export const MESSAGE_FORMAT = {
  JSON: 'json',
  TEXT: 'text',
  BINARY: 'binary',
  PROTOBUF: 'protobuf',
} as const;

export const COPY_ELEMENT = {
  EXEC_COMMAND_COPY: 'copy',
  ORGANIZER_EDITABLE_PRE_FORM_CONTAINER: 'pre-meeting-container',
  ORGANIZER_IN_MEETING_CONTAINER: 'in-meeting-container',
  CLIPBOARD_COPY_TEXT_SELECTOR_ID: 'copyContents',
  ORGANIZER_AUA_CONTAINER: 'pre-meeting-aua-container',
} as const;

export const SERVER_MESSAGE = {
  NO_CAPTIONER: 'Captioner is disconnected',
  STOP_CAPTIONS: 'Stop Captions.',
} as const;

export const TEAMS_MEETING_LINK_PARAMS = {
  JOIN_MEETING: 'meetup-join',
  CHAT: 'chat',
} as const;

export const LANGUAGE_CAPTION_CODE = {
  ZH: 'zh',
  LZH: 'lzh',
} as const;

export const ADAPTIVE_CARD_SCHEMA = {
  CARD_TYPE: 'AdaptiveCard',
  BODY_TYPE: 'TextBlock',
  ACTION_SUBMIT: 'Action.Submit',
  CARD_SCHEMA: 'http://adaptivecards.io/schemas/adaptive-card.json',
  VERSION: '1.2',
  CARD_STYLE: 'positive',
};
