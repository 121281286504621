// Constants
import { SNACKBAR_TYPE, USER_ROLE } from 'CONSTANTS/enum';
import { TEAMS_ERROR, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { TAB_LOACTION } from 'CONSTANTS/apiConstants';

// Hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSnackbar from 'HOOKS/useSnackbar';
import useMeetingDetails from 'HOOKS/useMeetingDetails';

// Service
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

// Store
import { meetingSelector as selector } from 'STORE/meetingSlice';

// Utils
import { ISubmitMeeting } from 'UTILS/apiInterface';

const usePremeeting = () => {
  const showSnackbar = useSnackbar();
  const [editMode, setEditMode] = useState(true);
  const currentUserRole = useSelector(selector.currentUserRole);
  const [languageOptions, setLanguageOptions] = useState([]);
  // AUA will be enabled if customer is allowed
  const [auaFeatureEnabled, setAUAFeatureEnabled] = useState(false);
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const {
    isAuthorized,
    loader,
    fetchMeetingDetails,
    refetchMeetingByProvidingPermission,
    submitData,
    getCaptionLanguage,
    fetchAuaEnabledForCustomer,
  } = useMeetingDetails();

  const fetchMeeting = async ({ usePermissionScope } = { usePermissionScope: false }) => {
    logger.debug('fetchMeeting', 'usePermissionScope:', usePermissionScope);
    const meetingDetails = usePermissionScope
      ? await refetchMeetingByProvidingPermission(TAB_LOACTION.preMeeting)
      : await fetchMeetingDetails(TAB_LOACTION.preMeeting);
    if (!meetingDetails?.languages) {
      // If there is not language show PreMeetingForm component
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  // Fetching customer allowed AUA
  const fetchCustomerAllowedAUA = async () => {
    try {
      const aua = await fetchAuaEnabledForCustomer();
      logger.debug('Customer Allowed AUA: ', aua);
      setAUAFeatureEnabled(aua);
    } catch (error) {
      logger.error(TEAMS_ERROR.FETCH_AUA_ALLOWED, error);
    }
  };

  // Fetching supportd languages
  /**
   * languages: [
   *  {
   *    name: language,
   *    code: language code,
   *  }
   * ]
   */
  const setLanguages = async () => {
    try {
      const languages = await getCaptionLanguage();
      logger.debug('Supported Languages: ', languages.languages);
      setLanguageOptions(languages.languages);
    } catch (error) {
      logger.error(TEAMS_ERROR.FETCH_LANGUAGE, error);
    }
  };

  const onSubmit = async ({
    languages,
    isPartialUpdate,
    universalAccessEnabled,
  }: ISubmitMeeting) => {
    try {
      await submitData({
        languages,
        isPartialUpdate,
        universalAccessEnabled,
      });
      // opens snackbar notification
      showSnackbar({
        message: t('SUBMIT_REQ_SUCCESS_MSG'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
      // setting editmode to false
      setEditMode(false);
    } catch (error: any) {
      logger.error(TEAMS_ERROR.SUBMIT_REQUEST, error);
      // opens snackbar notification
      showSnackbar({
        message: error.message,
        type: SNACKBAR_TYPE.DANGER,
      });
    }
  };

  useEffect(() => {
    // IIEF for fetchMeeting() and setLanguage()
    (async () => {
      try {
        logger.info('Fetching supported languages and meeting details');
        await Promise.all([fetchMeeting(), setLanguages(), fetchCustomerAllowedAUA()]);
      } catch (error) {
        logger.error(TEAMS_ERROR.FETCH_MEETING, error);
      }
    })();
  }, []);

  return {
    editMode: editMode && currentUserRole === USER_ROLE.ORGANIZER,
    currentUserRole,
    languageOptions,
    isAuthorized,
    loader,
    auaFeatureEnabled,
    setEditMode,
    fetchMeeting,
    onSubmit,
    t,
  };
};

export default usePremeeting;
