import { Flex, Text } from '@fluentui/react-northstar';
import { COPYRIGHT_TEXT_ALIGNMENT, FRAME_CONTEXT } from 'CONSTANTS/enum';
import { COPYRIGHT_TEXT } from 'CONSTANTS/teamsConstants';

// renders Akouo logo at bottom of page
const TabFooter = ({ tabLocation }: { tabLocation: string }) => {
  return (
    <Flex
      vAlign="center"
      hAlign={
        tabLocation === FRAME_CONTEXT.sidePanel
          ? COPYRIGHT_TEXT_ALIGNMENT.CENTER
          : COPYRIGHT_TEXT_ALIGNMENT.END
      }
    >
      <Text size="small" content={COPYRIGHT_TEXT} />
    </Flex>
  );
};

export default TabFooter;
