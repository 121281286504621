import useActions from 'HOOKS/useActions';
import logger from 'SERVICES/logger';
import { captionsActions } from 'STORE/captionsSlice';
import { CALL_STATUS, CAPTIONER_STATUS } from 'CONSTANTS/enum';
import { SERVER_MESSAGE } from 'CONSTANTS/teamsConstants';
import { useRef } from 'react';

const useMessage = () => {
  const {
    setServerMessage,
    setCaptions,
    setIsCaptionerPresent,
    setCallStatus,
    setCaptionerStatus,
  } = useActions(captionsActions);

  const currentCaptionsRef = useRef<any>(null);

  // handling captions data
  const handleCaptions = (eventData: any) => {
    logger.debug('handleCaptions: ', eventData);
    // storing captions in store
    currentCaptionsRef.current = eventData;
    setCaptions(eventData);
  };

  // handling server-message data
  const handleWsMessage = (eventData: any) => {
    logger.debug('ws-message', eventData);
    if (eventData.msg?.serverMessage) {
      logger.debug('server-message', eventData.msg);
      // if server message = captioner Disconnected then set Captioner presence to false
      if (eventData.msg?.serverMessage === SERVER_MESSAGE.NO_CAPTIONER)
        setIsCaptionerPresent(false);
      // storing server messages in store
      setServerMessage(eventData.msg?.serverMessage);
    }
  };

  // handling captioner status data
  const handleCallStatus = (eventData: any) => {
    // checking caption status
    switch (eventData.status) {
      case CALL_STATUS.INLOBBY:
        // setting captioner presence to false
        setIsCaptionerPresent(false);
        // storing captioner status in store
        setCallStatus(CALL_STATUS.CONNECTING);
        break;
      case CALL_STATUS.CONNECTED:
        // setting captioner presence to true
        setIsCaptionerPresent(true);
        // storing captioner status in store
        setCallStatus(CALL_STATUS.CONNECTED);
        break;
      case CALL_STATUS.DISCONNECTED:
        // setting captioner presence to false
        setIsCaptionerPresent(false);
        // storing captioner status in store
        setCallStatus(CALL_STATUS.DISCONNECTED);
        break;
      default:
        // unknown captioner state
        logger.info('Warning: unknown captioner state', eventData.status);
        break;
    }
  };

  // handling captioner status
  const handleCaptionerStatus = (eventData: any) => {
    if (eventData.status === CAPTIONER_STATUS.CONNECTED) {
      // setCaptioner Status as connected
      setCaptionerStatus(CAPTIONER_STATUS.CONNECTED);
    } else {
      // setCaptioner Status as disconnected
      setCaptionerStatus(CAPTIONER_STATUS.DISCONNECTED);
    }
  };

  return {
    handleCaptions,
    handleWsMessage,
    handleCallStatus,
    handleCaptionerStatus,
    currentCaptions: currentCaptionsRef.current,
  };
};

export default useMessage;
