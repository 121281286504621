import { createSlice } from '@reduxjs/toolkit';

// Constant
import { CREATE_MEETING_RESPONSE } from 'CONSTANTS/apiConstants';
import { USER_ROLE } from 'CONSTANTS/enum';

// Utils
import { ISetLanguage, ISpokenLanguage } from 'UTILS/teamsInterface';

interface IInitialState {
  meetingDetails: object;
  currentUserRole: number;
  meetingLanguage: ISetLanguage[];
  meetingSpokenLanguages: ISpokenLanguage[];
  selectedSpokenLanguages: ISpokenLanguage | object;
  captionerSecret: string | null;
  universalAccessEnabled: boolean;
  customerAllowedAUA: boolean;
}

const initialState: IInitialState = {
  meetingDetails: {},
  currentUserRole: USER_ROLE.ANONYMOUS,
  meetingLanguage: [],
  meetingSpokenLanguages: [],
  selectedSpokenLanguages: {},
  captionerSecret: null,
  universalAccessEnabled: false,
  customerAllowedAUA: false,
};

const meetingSlice = createSlice({
  name: 'teamsMeeting',
  initialState,
  reducers: {
    setMeetingDetails: (state, action) => {
      state.meetingDetails = action.payload;
      if (action.payload?.languages) {
        state.meetingLanguage = action.payload?.languages;
      }

      if (action.payload?.spokenLanguages) {
        state.meetingSpokenLanguages = action.payload?.spokenLanguages;
      }

      if (action.payload?.captionerInformation) {
        state.captionerSecret = action.payload?.captionerInformation?.captionerSecret;
      }

      state.universalAccessEnabled = action.payload?.universalAccessEnabled;
      state.customerAllowedAUA = action.payload?.customerEnabledAua;
    },
    setCurrentUserRole: (state, action) => {
      state.currentUserRole = action.payload;
    },
    setMeetingLanguages: (state, action) => {
      state.meetingLanguage = action.payload;
    },
    setSelectedSpokenLanguages: (state, action) => {
      state.selectedSpokenLanguages = action.payload;
    },
    setCaptionerSecret: (state, action) => {
      if (action.payload?.msg?.message === CREATE_MEETING_RESPONSE)
        state.captionerSecret = action.payload?.captionerSecret;
    },
    setUniversalAccessEnabled: (state, action) => {
      state.universalAccessEnabled = action.payload;
    },
    setCustomerAllowedAUA: (state, action) => {
      state.customerAllowedAUA = action.payload;
    },
  },
});

export default meetingSlice.reducer;

export const meetingActions = { ...meetingSlice.actions };

export const meetingSelector = {
  meetingDetails: (store: any) => store.teamsMeeting.meetingDetails,
  currentUserRole: (store: any) => store.teamsMeeting.currentUserRole,
  meetingLanguages: (store: any) => store.teamsMeeting.meetingLanguage,
  selectedSpokenLanguages: (store: any) => store.teamsMeeting.selectedSpokenLanguages,
  captionerSecret: (store: any) => store.teamsMeeting.captionerSecret,
  universalAccessEnabled: (store: any) => store.teamsMeeting.universalAccessEnabled,
  customerAllowedAUA: (store: any) => store.teamsMeeting.customerAllowedAUA,
};

export const selectMeetingSpokenLanguages = (store: any) =>
  store.teamsMeeting.meetingSpokenLanguages;

export const selectMeetingLanguage = (store: any) => store.teamsMeeting.meetingLanguage;
export const selectUniversalAccessEnabled = (store: any) =>
  store.teamsMeeting.universalAccessEnabled;
