import { Text, Flex, Dropdown, Button, Checkbox } from '@fluentui/react-northstar';

// Constants
import {
  LANGUAGE_PREFIX,
  MIN_LANGUAGES_SELECTED,
  TEAMS_LANGUAGE_RESOURCE,
} from 'CONSTANTS/teamsConstants';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';

// Hooks
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSnackbar from 'HOOKS/useSnackbar';

// Slice
import {
  selectMeetingLanguage,
  meetingSelector,
  selectUniversalAccessEnabled,
  meetingActions,
} from 'STORE/meetingSlice';

// Services
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

// Utils
import { IPreMeetingForm, ISetLanguage } from 'UTILS/teamsInterface';

import 'FEATURES/tab/preMeeting/preMeetingForm/PreMeetingForm.css';
import useActions from 'HOOKS/useActions';
import useAua from 'HOOKS/useAua';

const PreMeetingForm = ({
  handleSubmit,
  languages,
  auaFeatureEnabled,
  handleCancelButton,
}: IPreMeetingForm) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const { setUniversalAccessEnabled, setCustomerAllowedAUA } = useActions(meetingActions);
  const selectedMeetingLanguages = useSelector(selectMeetingLanguage);
  const meetingLanguages = useSelector(meetingSelector.meetingLanguages);
  const [selectedLanguages, setSelectedLanguages] = useState(selectedMeetingLanguages);
  const [isChecked, setIsChecked] = useState(!!useSelector(selectUniversalAccessEnabled));
  const { copyAuaLink } = useAua();

  const structureLanguages = (languages: ISetLanguage[]) => {
    // restructuring language object for the dropdown component
    return languages.map((language: ISetLanguage) => ({
      header: language.name,
      value: language.code,
      key: language.code,
    }));
  };

  const languageOptions = useMemo(() => structureLanguages(languages), [languages]);
  setCustomerAllowedAUA(auaFeatureEnabled);

  const onSubmit = () => {
    logger.debug('Language Selected', selectedLanguages);
    logger.debug('Universal Access', isChecked);
    const isPartialUpdate = meetingLanguages?.length > 0;

    if (selectedLanguages.length < MIN_LANGUAGES_SELECTED) {
      showSnackbar({
        message: t('MINIMUM_LANGUAGES', { languageCount: MIN_LANGUAGES_SELECTED }),
        type: SNACKBAR_TYPE.DANGER,
        show: true,
      });
      return;
    }

    handleSubmit({
      languages: selectedLanguages,
      isPartialUpdate,
      universalAccessEnabled: isChecked,
    });
    setUniversalAccessEnabled(isChecked);
    return;
  };

  return (
    <Flex className="pre-meeting-form" column gap="gap.large">
      <Text
        className="pre-meeting-form__title"
        content={t('CONFIGURE_CAPTION_LANGUAGES')}
        size="large"
        weight="bold"
      />
      <Flex column gap="gap.medium">
        <Text content={t('ADD_LANGUAGES_LABEL')} />
        <Dropdown
          items={languageOptions}
          placeholder={`${t('CHOOSE_LANG_DROPDOWN_MSG')}`}
          noResultsMessage={t('LANGUAGE_NOT_FOUND')}
          getA11ySelectionMessage={{
            onAdd: (item: any) => {
              setSelectedLanguages((prevSelectedLanguages: ISetLanguage[]) => [
                ...prevSelectedLanguages,
                { name: item.header, code: item.key },
              ]);
              return '';
            },
            onRemove: (removedLanguage: any) => {
              setSelectedLanguages((prevSelectedLanguages: ISetLanguage[]) =>
                prevSelectedLanguages.filter(
                  (language: ISetLanguage) =>
                    language.name !== removedLanguage.header &&
                    language.code !== removedLanguage.key
                )
              );
              return '';
            },
          }}
          defaultValue={structureLanguages(selectedLanguages)}
          multiple
          search
          fluid
          inverted
        />
      </Flex>
      {auaFeatureEnabled && (
        <Flex column gap="gap.smaller">
          <Checkbox
            checked={isChecked}
            onChange={(e, data) => {
              setIsChecked(data?.checked || false);
            }}
            className="dropdown-label"
            label={t('AUA_TITLE')}
          />
          <Text>{t('AUA_DESCRIPTION1')}</Text>
          <Text>{t('AUA_DESCRIPTION2')}</Text>
          {isChecked && (
            <Flex className="my-2">
              <Button content={t('COPY_LINK_FOR_PARTICIPANTS')} secondary onClick={copyAuaLink} />
            </Flex>
          )}
        </Flex>
      )}
      <Flex gap="gap.medium">
        <Button content={t('SUBMIT_LANG_REQ_BTN')} primary onClick={onSubmit} />
        <Button content={t('CANCEL_LANG_REQ_BTN')} onClick={handleCancelButton} />
      </Flex>
    </Flex>
  );
};

export default PreMeetingForm;
