import { showGraphConsent } from 'FEATURES/authentication';

// Constants
import { TEAMS_ERROR, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';
import { USER_ROLE } from 'CONSTANTS/enum';
import { ERROR, STATUS_CODE } from 'CONSTANTS/apiConstants';

// Hooks
import { useState } from 'react';
import useActions from 'HOOKS/useActions';

// Service
import logger from 'SERVICES/logger';
import { useTranslation } from 'SERVICES/i18n';
import {
  getCustomerEnabledForAua,
  getLanguages,
  getMeetingDetails,
  putMeeting,
} from 'SERVICES/api';

// Store
import { meetingActions } from 'STORE/meetingSlice';

// Utils
import { getTeamsContext } from 'UTILS/utility';
import { ISubmitMeeting } from 'UTILS/apiInterface';

const useMeetingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [consentProvided, setConsentProvided] = useState(false);
  const [graphScope, setGraphScope] = useState();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const { setCurrentUserRole, setMeetingDetails, setMeetingLanguages, setCaptionerSecret } =
    useActions(meetingActions);

  // setting loader and message associated with it
  const setLoadingWithMessage = (message: string) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  // sets userRole in store as Organizer or Participant
  const setUserRole = async (organizer: any, userObjectId: string) => {
    if (organizer?.id === userObjectId) {
      logger.info('Current user is an Organizer');
      // storing user role in store
      setCurrentUserRole(USER_ROLE.ORGANIZER);
    } else {
      logger.info('Current user is not an Organizer');
      // storing user role in store
      setCurrentUserRole(USER_ROLE.PARTICIPANT);
    }
  };

  // ask for user authentication and refetches meeting details once authenticated and consented
  const refetchMeetingByProvidingPermission = async (tabLocation: string, scope?: any) => {
    logger.info('refetchMeetingByProvidingPermission');
    let meetingDetails = null;
    const context: any = await getTeamsContext();
    try {
      // opens popup window for consent
      const consent: any = await showGraphConsent(scope || graphScope);
      logger.debug('Authentication code: ', consent?.code);
      // fetching meeting details
      meetingDetails = await getMeetingDetails(context?.meeting.id, tabLocation, consent?.code);
      // storing meeting details in store
      setMeetingDetails(meetingDetails);
      // checking user role
      await setUserRole(meetingDetails?.organizer, context?.user.id);
      setConsentProvided(true);
    } catch (error: any) {
      logger.error(TEAMS_ERROR.FETCH_MEETING, error);
      if (error.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant
        setConsentProvided(true);
      } else {
        setConsentProvided(false);
      }
    }
    return meetingDetails;
  };

  const fetchMeetingDetails = async (tabLocation: string) => {
    // getting teams context
    const context: any = await getTeamsContext();
    setLoadingWithMessage(t('LOAD_MEETING_INFO_LOADER_MSG'));
    let meetingDetails = null;
    try {
      // calling API to fetch meeting details
      meetingDetails = await getMeetingDetails(context?.meeting.id, tabLocation);
      // storing meeting details in store
      setMeetingDetails(meetingDetails);
      // checking user role
      await setUserRole(meetingDetails?.organizer, context?.user.id);
      setConsentProvided(true);
    } catch (error: any) {
      logger.error(TEAMS_ERROR.FETCH_MEETING, error);
      if ((error.code === ERROR.INVALID_MEETING_REQUEST, error)) {
        if (error.resCode === STATUS_CODE.NOT_FOUND) {
          setConsentProvided(true);
        } else {
          logger.debug('setGraphScope', error.data?.scope);
          setGraphScope(error.data?.scope);
          // refetching meeting details
          meetingDetails = await refetchMeetingByProvidingPermission(
            tabLocation,
            error.data?.scope
          );
        }
      } else if (error.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant. so we don't require graph consent
        setConsentProvided(true);
      }
    }
    setLoading(false);
    return meetingDetails;
  };

  const submitData = async (meetingData: ISubmitMeeting) => {
    const { languages, isPartialUpdate, universalAccessEnabled } = meetingData;
    setLoadingWithMessage(t('SUBMIT_REQ_LOADER_MSG'));
    try {
      // getting teams context
      const context: any = await getTeamsContext();
      const meetingId = context.meeting.id;
      // calling put meeting API to create job
      const captionerDetails = await putMeeting({
        meetingId,
        languages,
        isPartialUpdate,
        universalAccessEnabled,
      });
      setCaptionerSecret(captionerDetails?.data);
      // update spoken language in store
      setMeetingLanguages(languages);
      logger.info('Your request has been successfully submitted.');
      setLoading(false);
    } catch (error: any) {
      logger.error(TEAMS_ERROR.SUBMIT_REQUEST, error);
      if (error.code === ERROR.INVALID_MEETING_REQUEST) {
        logger.debug(`setGraphScope ${error.data.scope}`);
        setGraphScope(error.data.scope);
        setConsentProvided(false);
      }
      // set loading false
      setLoading(false);
      throw error;
    }
  };

  const getCaptionLanguage = async () => {
    // calling get language API
    const languages = await getLanguages();
    return languages;
  };

  const fetchAuaEnabledForCustomer = async () => {
    try {
      const context: any = await getTeamsContext();
      const tenantId = context?.user?.tenant?.id;
      const result = await getCustomerEnabledForAua(tenantId);
      return result;
    } catch (error) {
      logger.error('Error while fetching AUA enabled for customer', error);
      return false;
    }
  };

  return {
    isAuthorized: consentProvided,
    loader: {
      isLoading: loading,
      message: loadingMessage,
    },
    fetchMeetingDetails,
    fetchAuaEnabledForCustomer,
    refetchMeetingByProvidingPermission,
    submitData,
    getCaptionLanguage,
  };
};

export default useMeetingDetails;
